.testimonial {
    width: 100%;
    margin-bottom: 180px;
}

.testimonial-head {
    width: 90%;
    margin: auto;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 34px;
    }
}

.testimonial-carousel-container {
    width: 80%;
    margin: auto;
    padding: 10px;
    height: 350px !important;
}



.testimonial-card {
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    height: 320px;
    margin: auto;

    .testimonial-user-pic {
        width: 130px !important;
        height: 125px;
        border-radius: 50%;
        object-fit: cover;
    }

    h1 {
        font-weight: 400;
    }

    h3 {
        width: 80%;
        margin: auto;
        margin-top: 10px;
        font-size: 15px;
        text-align: center;
        color: black;
        font-weight: 400;
    }
}

.testimonial-user {
    font-size: 6em;
    color: rgb(0, 0, 0);
}




@media only screen and (min-width: 300px) and (max-width: 680px) {
    .testimonial-carousel-container {
        width: 98%;
        margin: auto;
        height: fit-content;
    }

    .testimonial-card {
        border-radius: 4px;
        padding: 10px;
        width: 100%;
        height: 420px;
        margin: auto;
    
        h1 {
            font-weight: 400;
        }
    
        h3 {
            width: 100%;
            margin: auto;
            margin-top: 10px;
            font-size: 15px;
            color: rgb(20, 20, 20);
            font-weight: 400;
        }
    }
}