.section-services {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 100px;
}

.services-head {
    height: 20%;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 34px;
        margin-bottom: 20px;
    }
}

.services-collection {
    height: 80%;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.card {
    height: 450px;

    margin-right: 5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
    width: 340px;
    margin-bottom: 20px;

    &:hover {
        box-shadow: 0 0 30px rgba(39, 39, 39, 0.26);
        transition: ease-in 0.2s;
    }
}

.card-img {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 4px;

        &:hover {
            transition: ease-in 0.2s;
            transform: translate(1.02);
        }
    }
}

.card-desc {
    width: 100%;
        
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    h4 {
        color: rgb(31, 31, 31);
        font-size: 15px;
        font-weight: 400;
        text-align: left;
    }
}

.card-more {
    width: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    button {
        width: 120px;
        padding: 10px;
        cursor: pointer;
        border-radius: 4px;
        color: white;
        box-shadow: 2px 5px 2px rgba(68, 68, 68, 0.096);
        background-color: rgb(66, 66, 255);
        outline: none;
        border: none;

        &:hover {
            opacity: 0.8;
        }
    }
}