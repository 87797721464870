/*.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 10;
}*/

.logo-container {
    img {
        margin-left: 20px;
        width: 130px;
        height: 60px;
    }
}

.nav-options {
    width: 250px;
    display: flex;
    justify-content: space-evenly;
    .nav-link {
        color: white;
        font-weight: 400;
        font-size: 17px;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}