.section-picktaxi {
    width: 100%;
    margin: auto;
    margin-bottom: 100px;
    padding: 5px;
    height: fit-content;
    background-color: rgba(236, 236, 236, 0.712);
}

.picktaxi-head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 34px;
        margin-bottom: 20px;
    }
}

.picktaxi-info {
    display: flex;
    width: 70%;
    text-align: center;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    align-items: center;
    h4 {
        font-weight: 400;
        margin-bottom: 20px;
        color: rgb(17, 17, 17);
    }

    h5 {
        font-weight: 400;
        color: gray;
        margin-bottom: 10px;  
    }
}

.picktaxi-collection {
    margin: auto;
    padding: 10px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 320px);
    row-gap: 10px;

    .picktaxi-img-desc {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.808);
        padding: 10px;
        font-size: 18px;
        font-weight: 400;
        text-shadow: 0 0 30px black;
        color: white;
        visibility: hidden;
        opacity: 0;
        transition: opacity .2s, visibility .2s;
    }

    .picktaxi-img-wrap {
        height: 212px;
        width: 310px;
        position: relative;

        .picktaxi-img {
            border-radius: 2px;
            object-fit: cover;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.747) !important;
            height: 100% !important;
            width: 100% !important;
        }
    }
    .picktaxi-img-wrap:hover .picktaxi-img-desc {
        visibility: visible !important;
        opacity: 1;
    }
}


@media only screen and (min-width: 300px) and (max-width: 680px) {
    .picktaxi-info {
        display: flex;
        width: 90%;
        text-align: center;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
        align-items: center;
        h4 {
            font-weight: 400;
            font-size: 15px;
            margin-bottom: 20px;
            color: rgb(17, 17, 17);
        }
    
        h5 {
            font-weight: 400;
            color: gray;
            margin-bottom: 10px;  
        }
    }

    .picktaxi-collection-mob {

        .picktaxi-mob-carousel {
        }

        .picktaxi-img-desc-mob-top {
            position: absolute;
            padding: 10px;
            font-size: 18px;
            font-weight: 400;
            color: white;
            text-shadow: 0 0 30px black;    
            right: 0;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.74);
        }

        .picktaxi-img-desc-mob-down {
            position: absolute;
            padding: 10px;
            font-size: 18px;
            display: flex;
            font-weight: 400;
            color: white;
            text-shadow: 0 0 30px black;    
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.74);
        }

        .picktaxi-img-wrap-mob {
            height: 100%;
            width: 100%;
            position: relative;
            .picktaxi-img-mob {
                border-radius: 2px;
                object-fit: cover;
                box-shadow: 0 0 30px rgba(0, 0, 0, 0.747) !important;
                height: 100% !important;
                width: 100% !important;
            }
        }
    }
}