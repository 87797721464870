.footer-end {
    width: 100%;
    padding: 20px;
    height: fit-content;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
    background-color: rgba(219, 219, 219, 0.795);
}

/*
    box-shadow: 0 4px 2px rgba(0,0,0,0.02),
                0 8px 6px rgba(0,0,0,0.03),
                0 16px 10px rgba(0,0,0,0.04),
                0 26px 18px rgba(0,0,0,0.05);
*/

.footer-info {
    display: grid;  
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr !important;
    justify-content: center;
    margin-bottom: 20px;

    .allucabs-info {
        padding: 10px;
        height: 100%;
        margin-bottom: 10px;
        .allucabs-info-head {
            h2 {
                margin-bottom: 10px;
            }
        }

        .allucabs-info-desc {
            h4 {
                color: rgb(19, 19, 19);
                font-weight: 400;
            }
            margin-bottom: 10px;
        }

        .social-links {
            display: flex;
            width: fit-content;
            justify-content: space-evenly;
            align-items: center;

            .social-link-whats {
                font-size: 2.2em;
                color: rgb(4, 192, 4) !important;
            }
            
            .social-link-fb {
                font-size: 2.2em;
                color: rgb(79, 79, 255);
            }

            .social-link-insta {
                color: white;
                font-size: 2.3em;
                border-radius: 10px;
                background-image: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            }

            .social-link-whats, .social-link-fb, .social-link-insta {
                cursor: pointer;
                margin-left: 10px;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }


    .footer-picktaxi {
        padding: 10px;       
        height: 100%;
        margin-bottom: 10px;
        .footer-picktaxi-head {
            h2 {
                margin-bottom: 10px;
            }
        }

        .footer-picktaxi-cars {
            h4 {
                font-weight: 400;
            }
        }
    }

    .contact-info {
        padding: 10px;
        height: 100%;
        .contact-info-head {
            h2 {
                margin-bottom: 10px;

            }
        }

        .contact-info-details {
            h4 {
                font-weight: 400;
            }
        }
    }
}


.footer-copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h5 {
        font-weight: 400;
        color: gray;
    }
}

@media only screen and (min-width: 300px) and (max-width: 680px) {

    .footer-info {
        display: block;
    }

}