.section-carousel {
    width: 100%;
    margin-bottom: 50px;
}


.carousel {
    .carousel-img {
        img {
            object-fit: cover;
            height: 500px;
            filter: brightness(40%);
            background-position: center;
            background-size: cover;
            position: relative !important;
        }
        .legend {
            position: absolute !important;
            opacity: 1 !important;
            margin-bottom: 100px;
            font-size: 4em;
            font-weight: 400;
            text-shadow: 10px 20px 30px black;
            color: white;
            background: none;
        }
    }
}


@media only screen and (min-width: 300px) and (max-width: 680px) {
    .carousel {
        .carousel-img {
            img {
                object-fit: cover;
                height: 600px;
                filter: brightness(30%);
                background-position: center;
                background-size: cover;
                position: relative !important;
            }
            .legend {
                position: absolute !important;
                opacity: 1 !important;
                margin-bottom: 220px;
                font-size: 2.1em;
                font-weight: 400;
                color: white;
                background: none;
            }
        }
    }
}