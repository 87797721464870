.floater {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .whats-float {
        background-color: white;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.507);
        padding: 1px;   
        border-radius: 50%;
        color: rgb(0, 182, 0);
        border: rgb(0, 182, 0) solid 2px;
        font-size: 3.5em;
        margin-top: 10px;
    }

    .call-float {
        color: rgb(50, 50, 255);
        padding: 10px;
        background-color: white;
        border-radius: 50%;
        border: blue solid 2px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.507);
        font-size: 3.5em;
    }
}