.quick-contact-section {
    width: 98%;
    height: 300px;
    border-radius: 4px;
    margin: auto;
    cursor: pointer;
    display: flex;
    margin-bottom: 120px;
	background: linear-gradient(-55deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);    justify-content: center;
    align-items: center;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.555);

    &:hover {
        filter: brightness(90%);
        transition: 0.1s ease-in-out;
    }
}

.quick-contact-container {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    h1 {
        font-weight: 800;   
        color: white;
        font-size: 2.5em;
        text-shadow: 0 0 30px rgba(0, 0, 0, 0.521);
    }

    h4 {
        font-weight: 400;
        font-size: 1.2em;
        color: white;
        text-shadow: 0 0 30px rgba(0, 0, 0, 0.527);
    }

    .quick-call-callicon {
        color: rgba(47, 33, 250, 0.842);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.411);
        font-size: 2.5em;
        background-color: white;
        margin-bottom: 10px;
        border-radius: 3px;
    }
}
