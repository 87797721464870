

.packages {
    width: 95%;
    margin-bottom: 120px !important;
    margin: auto;
}
.packages-right {
    width: 50%;
    padding: 10px;
}

.packages-info {
    display: flex;
}

.about-kochi-head {
    h1 {
        font-size: 28px;
    }
    margin-bottom: 10px;
}
//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
.about-kochi-desc {
    h4 {
        color: gray;
        font-weight: 400;
    }
}

.packages-head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    h1 {
        margin-bottom: 10px;
    }

    h4 {
        color: gray;
        font-weight: 400;
    }
}


.packages-img {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    width: 50%;
    justify-content: center;
    height: fit-content;
    row-gap: 5px;
    column-gap: 5px;
    img {
        width: 275px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.747) !important;
        height: 200px;
        &:hover {
            transition: ease-in 0.2s;
            transform: scale(1.01);
        }
    }
}

@media only screen and (min-width: 300px) and (max-width: 680px) {
    .packages-info {
        display: flex;
        flex-direction: column;
    }

    .packages-img {
        display: grid;
        grid-template-columns: repeat(auto-fill, 1fr);
        width: 100%;
        justify-content: center;
        height: fit-content;
        div {
            width: 100%;
        }
        img {
            width: 100%;
            border-radius: 4px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.747) !important;
            height: 200px;
            transition: 0.1s ease-in;

            &:hover {
                transition: ease-in 0.2s;
                transform: scale(1.01);
            }
        }
    }

    .packages-right {
        width: 100%;
        padding: 10px;
    }
    

    .about-kochi-head {
        h1 {
            font-size: 28px;
        }
        margin-bottom: 10px;
    }
    .about-kochi-desc {
        width: 100%;
        h5 {
            color: gray;
            font-weight: 400;
        }
    }
}
